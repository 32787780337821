<template>
  <UiPage
    :title="$t('app.page.store')"
    :margin-bottom="20"
  >
    <template #top>
      <BackButton />
    </template>

    <template #content>
      <!-- <div class="tabs">
        <TabButton
          :text="$t('views.default.store.containers.containers')"
          :value="'StoreContainers'"
          :active="$route.name"
          @click="$router.push({ name: 'StoreContainers' })"
        />

        <TabButton
          :text="$t('views.default.store.archive.archive')"
          :value="'StoreContainerArchive'"
          :active="$route.name"
          @click="$router.push({ name: 'StoreContainerArchive' })"
        />
      </div> -->

      <p class="description">
        <!-- {{ $t('views.default.store.text') }} -->
      </p>

      <div class="grid">
        <template v-if="!preloader">
          <Card
            v-for="item in data"
            :key="item.id"
            :value="item"
          />
        </template>

        <template v-else>
          <div
            v-for="item in 4"
            :key="item"
            class="grid-preloader-card"
          >
            <Preloader
              :visible="preloader"
            />
          </div>
        </template>
      </div>
    </template>
  </UiPage>
</template>

<script>
import UiPage from '@/ui/Page.vue'
import BackButton from '@/ui/buttons/BackButton.vue'
// import TabButton from '@/ui/buttons/TabButton.vue'
import Card from '@/modules/store/ContainerCard.vue'
import Preloader from '@/ui/preloader/SkeletonAnimatePreloader.vue'

export default {
  components: {
    UiPage,
    BackButton,
    // TabButton,
    Card,
    Preloader
  },
  data () {
    return {
      mountFetch: false,
      preloader: true,
      length: 2,
      data: []
    }
  },
  activated () {
    if (this.mountFetch) {
      this.fetch()
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    async fetch () {
      try {
        const resp = await this.$axios.get(
          '/private/containers?not-filled=true&offset=0')

        if (resp.status === 200) {
          this.data = resp.data.data
        }
      } catch (err) {
        throw new Error(err)
      } finally {
        this.preloader = false
        this.mountFetch = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tabs {
  display: flex;
}

p.description {
  max-width: 900px;
  margin: 20px 0 30px;
  color: black;
  font-size: 16px;
  line-height: 150%;
}

.grid {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, 1fr);

  @media screen and (max-width: 1700px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 670px) {
    grid-template-columns: repeat(1, 1fr);
  }

  .grid-preloader-card {
    position: relative;
    height: 420px;
    background: #FFFFFF;
    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.06);
    border-radius: 15px;
    overflow: hidden;
  }
}

@media screen and (max-width: 768px) {
  .tabs {
    padding: 0 17px;
  }

  p.description {
    padding: 0 17px;
  }

  .grid {
    padding: 0 17px;
  }
}
</style>
